import styled from 'styled-components'
import { Box } from '~styles/common'

export const MobileImageWrapper = styled(Box)`
  height: 200px;
  margin-top: -20px;
  margin-right: -20px;
  margin-left: -20px;
`

export const ImageWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`