import styled from 'styled-components'

export const FigureImage = styled.img`
  margin-bottom: 20px;
  align-self: flex-start;
  max-height: 700px;
  max-width: 100%;
  width: auto;
`
export const FigureCaption = styled.figcaption`
  min-height: 7em;
`
