import React from 'react'

import TextBlock from '~components/TextBlock'
import HTMLBlock from '~components/HTMLBlock'
import QuoteBlock from '~components/QuoteBlock'
import ProcessBlock from '~components/ProcessBlock'
import InsightBlock from '~components/InsightBlock'
import ImageBlock from '~components/ImageBlock'
import DoubleImageBlock from '~components/DoubleImageBlock'
import ComboBlock from '~components/ComboBlock'
import ShowcaseBlock from '~components/ShowcaseBlock'

const SectionBlock = ({ type, ...props }) => {
  switch (type) {
    case 'Craft_SectionsHtmlblock':
      return <HTMLBlock {...props} />

    case 'Craft_SectionsTextBlock':
      return <TextBlock {...props} />

    case 'Craft_SectionsQuoteBlock':
      return <QuoteBlock {...props} />
    
    case 'Craft_SectionsProcessBlock':
      return <ProcessBlock {...props} />
    
    case 'Craft_SectionsInsightBlock':
      return <InsightBlock {...props} />

    case 'Craft_SectionsDoubleImageBlock':
      return <DoubleImageBlock {...props} />

    case 'Craft_SectionsImageBlock':
      return <ImageBlock {...props} />

    case 'Craft_SectionsComboBlock':
      return <ComboBlock {...props} />
    
    case 'Craft_SectionsShowcaseBlock':
      return <ShowcaseBlock {...props} />
      
    default:
      return <div/>
  }
}

export default SectionBlock
