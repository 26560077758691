import React from 'react'

import { Grid, Cell, Box } from '~styles/common'
import { ImageWrapper, MobileImageWrapper } from './style'
import Block from '~components/Block'

const InsightBlock = ({
  backgroundColor,
  body,
  image,
  ...props
}) => {
  return (
    <Block
      bg={backgroundColor}
      {...props}
    >
      <MobileImageWrapper
        display={{ _: 'block', sm: 'none' }}
        gridColumn="span 5"
      >
        {image &&
          <Box
            width="100%"
            height="100%"
            backgroundImage={`url(${image.url})`}
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            backgroundSize="cover"
            role="img"
            aria-label={image.title}
          />
        }
      </MobileImageWrapper>
      <Cell
        gridColumn={{ _: 'span 5', lg: 'span 5' }}
        minHeight={{ _: '0', sm: '300px', lg: '450px' }}
      >
        <h2
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </Cell>
      <ImageWrapper display={{ _: 'none', sm: 'block' }}>
        <Grid height="100%">
          <Cell
            gridColumn={{ sm: '6 / span 5', lg: '8 / span 5' }}
            marginRight={['-20px', '-60px']}
            marginTop="-20px"
          >
            {image &&
              <Box
                width="100%"
                height="100%"
                backgroundImage={`url(${image.url})`}
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                backgroundSize="cover"
              />
            }
          </Cell>
        </Grid>
      </ImageWrapper>
      <Cell />
    </Block>
  )
}

export default InsightBlock
