import { flattenContent, mapSection, staticSuperTable } from '~utils/mapping-filters'

const mapEntry = (entry) => {
  const masthead = staticSuperTable(entry.masthead)
  const metadata = staticSuperTable(entry.metadata)

  return {
    masthead: {
      ...flattenContent(masthead, 'subheading'),
      image: masthead.image[0]
    },
    sections: entry.sections.map(mapSection),
    metadata
  }
}

export const mapCraft = (craft) => {
  return {
    ...craft,
    entry: mapEntry(craft.entry)
  }
}