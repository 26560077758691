import React from 'react'

import { Cell, Box } from '~styles/common'
import Block from '~components/Block'
import useBreakpoint from '~utils/use-breakpoint'

const HTMLBlock = ({
  body,
  ...props
}) => {

  const { sm } = useBreakpoint()

  return (
  <Box
    backgroundColor="white"
    paddingRight={sm ? "60px" : "0"}
    paddingBottom="20px"
    position="relative"
    {...props}
  >
    <Box
      dangerouslySetInnerHTML={{ __html: body }}
    />
  </Box>
  )
}

export default HTMLBlock
