import React from 'react'
import Block from '~components/Block'
import { Cell } from '~styles/common'
import { FigureImage, FigureCaption } from './style'

const DoubleImageBlock = ({
  backgroundColor,
  images,
  ...props
}) => {
  return (
    <Block
      bg={backgroundColor}
      {...props}
    >
      {images.map(imageEntry =>
        <Cell
          display="flex"
          flexDirection="column"
          alignContent="flex-end"
          justifyContent="flex-end"
          key={imageEntry.id}
          gridColumn={{ _: 'span 5', sm: 'span 6', lg: 'span 6' }}
        >
          <FigureImage
            width="100%"
            src={imageEntry.image[0].url}
            alt={imageEntry.image[0].title}
          />
          <FigureCaption>
            {imageEntry.caption}
          </FigureCaption>
        </Cell>
      )}
    </Block>
  )
}

export default DoubleImageBlock
