import React from 'react'
import { Cell } from '~styles/common'
import Block from '~components/Block'
import { AuthorImage } from './style'

const QuoteBlock = ({
  heading,
  quote,
  image,
  fullName,
  organisation,
  backgroundColor,
  ...props
}) => {
  return (
    <Block
      bg={backgroundColor}
      heading={heading}
      {...props}
    >
      <Cell gridColumn={{ _: 'span 5', sm: '6 / span 5', lg: '8 / span 5' }}>
        <h2 dangerouslySetInnerHTML={{ __html: quote }} />
      </Cell>
      <Cell
        display={{ _: 'none', sm: 'block' }}
        gridColumn={{ _: 'span 5', lg: 'span 7' }}
      />
      {image &&
        <Cell gridColumn="span 1">
          <AuthorImage
            src={image.url}
            alt={image.title}
          />
        </Cell>
      }
      <Cell gridColumn="span 4">
        <p>
          {fullName}<br/>
          {organisation}
        </p>
      </Cell>
      <Cell />
    </Block>
  )
}

export default QuoteBlock
