import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import Block from "~components/Block"
import { Cell } from "~styles/common"
import theme from "~styles/theme"
import useBreakpoint from "~utils/use-breakpoint"

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const ProcessBlock = ({ backgroundColor, heading, steps, ...props }) => {
  const { sm, md } = useBreakpoint()
  const stepSpacingIncrement = 60

  const [step, setStep] = useState(0)
  const [stepSpacing, setStepSpacing] = useState(0)
  const prevStep = usePrevious({ step })

  useEffect(() => {
    if (prevStep) {
      if (step === 0) {
        setStepSpacing(0)
        return
      }

      setStepSpacing(step * stepSpacingIncrement)
    }
  }, [step])

  const chooseStep = stepNum => {
    setStep(stepNum)
  }
  return (
    <Block bg={backgroundColor} heading={heading} {...props}>
      <HeadingCell
        gridColumn={{ _: "span 5", sm: "6 / span 5", lg: "8 / span 5" }}
      >
        <StepList stepSpacing={`-${stepSpacing}px`}>
          {steps.map((stepObj, index) => (
            <StepListItem
              isActive={step === index}
              onClick={() => chooseStep(index)}
            >
              <span>{index + 1}</span>
            </StepListItem>
          ))}
        </StepList>
        <h2>{steps[step].heading}</h2>
      </HeadingCell>
      <Cell />
      <ImageCell gridColumn={{ _: "span 5", sm: "span 4", lg: "span 5" }}>
        {steps[step].image && (
          <img
            src={steps[step].image.url}
            alt={steps[step].image.title}
            width="100%"
          />
        )}
      </ImageCell>
      <Cell gridColumn={{ _: "span 5", sm: "6 / span 5", lg: "8 / span 5" }}>
        <div dangerouslySetInnerHTML={{ __html: steps[step].body }} />
      </Cell>
      <Cell />
    </Block>
  )
}

export default ProcessBlock

const ImageCell = styled(Cell)`
  z-index: 99;
`

const StepList = styled.ul`
  position: absolute;
  left: -61px;
  top: ${p => p.stepSpacing};
  margin: 0;
  margin-top: -5px;

  transition: top 250ms ease 0s;

  @media only screen and (max-width: ${theme.breakpoints[0]}) {
    right: 0;
    left: initial;
  }
`

const StepListItem = styled.li`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: ${p => (p.isActive ? "2px solid black;" : "none")};
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  opacity: ${p => (p.isActive ? 1 : 0.25)};
  cursor: pointer;
  &::before {
    display: none;
  }
`

const HeadingCell = styled(Cell)`
  position: relative;
`
