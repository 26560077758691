import React from 'react'

import PageLayer from '~components/PageLayer'
import SEO from '~components/SEO'
import Masthead from '~components/Masthead'
import SectionBlock from '~components/SectionBlock'

import { useCraft } from './query'

const CareersTemplate = () => {
  const { entry } = useCraft()

  return (
    <PageLayer>
      <SEO
        title="Careers"
        metadata={entry.metadata}
      />
      <Masthead
        {...entry.masthead}
      />
      {entry.sections.map((section, i) =>
        <SectionBlock key={i} {...section} />
      )}
    </PageLayer>
  )
}

export default CareersTemplate
