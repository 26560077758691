import React from 'react'
import Block from '~components/Block'
import { Box, Cell } from '~styles/common'

const ImageBlock = ({
  backgroundColor,
  image,
  position,
  fullscreen,
  ...props
}) => {
  if (fullscreen && image) {
    return (
      <Box
        paddingRight="60px"
        position="relative"
        zIndex={200}
      >
        <img
          width="100%"
          src={image.url}
          alt={image.title}
        />
      </Box>
    )
  }

  return (
    <Block
      bg={backgroundColor}
      {...props}
    >
      {position === 'right' &&
        <Cell
          display={{ _: 'none', sm: 'block' }}
          gridColumn={{ sm: 'span 4', lg: 'span 5' }}
        />
      }
      <Cell gridColumn={{ _: 'span 5', sm: 'span 6', lg: 'span 7' }}>
        {image &&
          <img
            width="100%"
            src={image.url}
            alt={image.title}
          />
        }
      </Cell>
      <Cell />
    </Block>
  )
}

export default ImageBlock
