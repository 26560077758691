import styled from 'styled-components'
import { Cell } from '~styles/common'

export const ShowcaseFrame = styled(Cell)`
  background: black;
  overflow-y: scroll;
  border: 20px solid black;
  border-radius: 14px;

  img {
    display: block;
  }
`
