import React from 'react'
import Block from '~components/Block'
import { Box, Cell } from '~styles/common'

const ComboBlock = ({
  backgroundColor,
  image,
  heading,
  body,
  ...props
}) => {
  return (
    <Block
      bg={backgroundColor}
      {...props}
    >
      <Cell gridColumn={{ _: 'span 4', lg: 'span 5' }}>
        <Box
          display="flex"
          height="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <h2>{heading}</h2>
          <div
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </Box>
      </Cell>
      <Cell gridColumn={{ _: 'span 5', lg: '8 / span 6' }}>
        {image &&
          <img
            style={{ display: 'block' }}
            src={image.url}
            alt={image.title}
            width="100%"
          />
        }
      </Cell>
      <Cell />
    </Block>
  )
}

export default ComboBlock
