import React from 'react'

import Block from '~components/Block'
import { Grid, Cell } from '~styles/common'
import { ShowcaseFrame } from './style'

const ShowcaseHalf = ({ image }) => {
  return (
    <Cell
      bg="neutral"
      gridColumn={{ _: 'span 5', lg: 'span 6' }}
    >
      <Grid gridTemplateColumns={{ _: 'repeat(5, 1fr)', lg: 'repeat(6, 1fr)' }}>
        <Cell gridColumn={{ _: 'span 5', lg: 'span 6' }} />
        <ShowcaseFrame
          height={{ _: '450px', lg: '600px' }}
          width={{ _: '270px', lg: '370px' }}
          gridColumn={{ _: '2 / span 3', lg: '2 / span 4' }}
        >
          {image &&
            <img
              src={image.url}
              alt={image.title}
              width="100%"
            />
          }
        </ShowcaseFrame>
        <Cell gridColumn={{ _: 'span 5', lg: 'span 6' }} />
      </Grid>
    </Cell>
  )
}

const Showcase = ({ image }) => {
  return (
    <Cell bg="neutral">
      <Grid>
        <Cell />
        <ShowcaseFrame
          height={{ _: '450px', lg: '600px' }}
          gridColumn={{ _: '2 / span 3', sm: '2 / span 8', lg: '2 / span 10' }}
        >
          {image &&
            <img
              src={image.url}
              alt={image.title}
              width="100%"
            />
          }
        </ShowcaseFrame>
        <Cell />
      </Grid>
    </Cell>
  )
}

const ShowcaseBlock = ({
  heading,
  body,
  images,
  ...props
}) => {
  const isManyImages = images.length > 1

  return (
    <Block
      bg="white"
      {...props}
    >
      {images.map((image, i) => {
        if (isManyImages) {
          return <ShowcaseHalf key={i} image={image} />
        }
        return <Showcase key={i} image={image} />
      })}
      {heading &&
        <Cell gridColumn="span 5">
          <h2>{heading}</h2>
        </Cell>
      }
      {body &&
        <Cell gridColumn={{ _: 'span 5', lg: '7 / span 6' }}>
          <p
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </Cell>
      }
      <Cell />
    </Block>
  )
}

export default ShowcaseBlock
